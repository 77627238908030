import { FC, useCallback, useContext, useMemo } from "react";
import classNames from "classnames";
import { IBetResponse, ITicketResponse } from "@finbackoffice/clientbff-client";
import { ConfigContext, ModalsContext, useCollapseHeight } from "@finbackoffice/site-core";
import Link from "next/link";
import { useRouter } from "next/router";
import { TicketStatus, TicketType, TranslationScopes, WalletType } from "@finbackoffice/enums";
import { useDate, DEFAULT_DATE_FORMAT, TICKET_STATUS, useTickets } from "hooks";
import ScrollComponent from "components/base/scroll/ScrollComponent";
import Translate from "components/base/translate/Translate";
import { SIRWidgetContext } from "contexts";
import { CurrencyFormatter } from "components/base/currency-formater/CurrencyFormater";
import { Svg } from "components/base/svg/Svg";
import { Chars } from "utils/strings";
import { RouterQuery } from "utils/constants";
import SportIcon from "components/base/sport-icon/SportIcon";
import styles from "./betslip-history-block.module.sass";
import BetSlipHistoryStatistics from "./statistics/BetSlipHistoryStatistics";

interface ISportSingleBetViewProps {
    outcome: IBetResponse;
    bet: ITicketResponse;
}

interface ISportOtherBetRowProps {
    bet: ITicketResponse;
}

type IOutcomeInfoProps = {
    outcome: IBetResponse;
    isExpress?: boolean;
};

const OutcomeInfo: FC<IOutcomeInfoProps> = ({ outcome, isExpress }) => {
    const { checkSupportedSport } = useContext(SIRWidgetContext);

    return (
        <div className={styles.info}>
            <p>
                {isExpress && (
                    <Svg
                        src={`/common/desktop/base-icons/status-${outcome.status.toLowerCase()}.svg`}
                        wrapper="span"
                        className="svg-status-icon"
                    />
                )}
                <SportIcon sportId={outcome.sport_id} />
                <span
                    className={styles.teams}
                    title={`${outcome.home_team} vs ${outcome.away_team}`}>{`${outcome.home_team} vs ${outcome.away_team}`}</span>

                {isExpress && Boolean(checkSupportedSport?.(outcome.sport_id)) && (
                    <BetSlipHistoryStatistics bet={outcome} />
                )}
            </p>
            <p>{outcome.market_name}</p>
            <p className={styles.odds}>
                {outcome.outcome_name}
                <strong>{outcome.odds}</strong>
            </p>
        </div>
    );
};

const SportSingleBetView: FC<ISportSingleBetViewProps> = ({ bet, outcome }) => {
    const { formatDate } = useDate();
    const { checkSupportedSport } = useContext(SIRWidgetContext);
    const { siteConfigReady } = useContext(ConfigContext);
    const isStatusAccepted = bet.status === TicketStatus.Accepted;

    return (
        <div className={classNames(styles.bet, styles[`icon-${outcome.status.toLowerCase()}`])}>
            <div className={styles.header}>
                <Svg
                    src={`/common/desktop/base-icons/status-${bet.status.toLowerCase()}.svg`}
                    wrapper="span"
                    className="svg-icon"
                />
                <span className={styles.label}>
                    {formatDate(new Date(bet.created_at), `${DEFAULT_DATE_FORMAT}, HH:mm`, false)}
                    {bet.closed_at && (
                        <>
                            {` / `}
                            {formatDate(
                                new Date(bet.closed_at),
                                `${DEFAULT_DATE_FORMAT}, HH:mm`,
                                false,
                            )}
                        </>
                    )}
                </span>
                {bet.wallet_type === WalletType.Bonus && (
                    <span className={styles.bonusLabel}>Bonus</span>
                )}

                {checkSupportedSport?.(bet.bets[0].sport_id) && (
                    <BetSlipHistoryStatistics bet={bet.bets[0]} />
                )}
            </div>
            <div className={styles.subheader}>
                <Translate tid={`betType_${bet.type.toLowerCase()}`} />
                <span>
                    <span className={styles.label}>
                        <Translate tid="betSlip_history_id" namespace={TranslationScopes.BetSlip} />
                        :{Chars.nbsp}
                    </span>
                    {bet.short_id}
                </span>
            </div>
            <OutcomeInfo outcome={outcome} />
            <div className={styles.stake}>
                <span className={styles.label}>
                    <Translate tid="betSlip_history_stake" namespace={TranslationScopes.BetSlip} />:
                </span>
                <strong>
                    <CurrencyFormatter
                        currency={bet.currency}
                        amount={bet.amount}
                        withCode={false}
                        withSymbol
                    />
                </strong>
            </div>
            <div className={styles.payout}>
                {isStatusAccepted ? (
                    <>
                        <span className={styles.label}>
                            <Translate tid="betSlip_toWin" namespace={TranslationScopes.BetSlip} />:
                        </span>
                        <strong>
                            <CurrencyFormatter
                                currency={bet.currency}
                                amount={(
                                    parseFloat(bet.amount) * parseFloat(outcome.odds)
                                ).toString()}
                                withCode={false}
                            />
                        </strong>
                    </>
                ) : (
                    <>
                        <span className={styles.label}>
                            <Translate
                                tid="betSlip_history_payout"
                                namespace={TranslationScopes.BetSlip}
                            />
                            :
                        </span>
                        {siteConfigReady && (
                            <strong>
                                <CurrencyFormatter
                                    currency={bet.currency}
                                    amount={bet.payout}
                                    withCode={false}
                                    withSymbol
                                />
                            </strong>
                        )}
                    </>
                )}
            </div>
        </div>
    );
};

const SportOtherBetView: FC<ISportOtherBetRowProps> = ({ bet }) => {
    const { formatDate } = useDate();
    const { opened, animating, headerRef, containerRef } = useCollapseHeight(false);
    const isStatusAccepted = bet.status === TicketStatus.Accepted;

    const renderMultibetBonus = useMemo(() => {
        const [, decimal] = bet.bonus.split(".");
        const hasDecimal = decimal && parseInt(decimal) > 0;

        return (
            <span className={styles.bonusLabel}>
                <Translate
                    tid="multibet_bonus"
                    replace={{
                        amount: hasDecimal ? parseFloat(bet.bonus) : parseInt(bet.bonus),
                    }}
                />
            </span>
        );
    }, [bet.bonus]);

    return (
        <div className={classNames(styles.bet, styles[`icon-${bet.status.toLowerCase()}`])}>
            <div className={styles.header}>
                <Svg
                    src={`/common/desktop/base-icons/status-${bet.status.toLowerCase()}.svg`}
                    wrapper="span"
                    className="svg-icon"
                />
                <span className={styles.label}>
                    {formatDate(new Date(bet.created_at), `${DEFAULT_DATE_FORMAT}, HH:mm`, false)}
                    {bet.closed_at && (
                        <>
                            {` /  `}
                            {formatDate(
                                new Date(bet.closed_at),
                                `${DEFAULT_DATE_FORMAT}, HH:mm`,
                                false,
                            )}
                        </>
                    )}
                </span>
                {bet.wallet_type === WalletType.Bonus && (
                    <span className={styles.bonusLabel}>Bonus</span>
                )}
                {bet.bonus && parseInt(bet.bonus) > 0 && renderMultibetBonus}
            </div>
            <div className={styles.subheader}>
                <Translate tid={`betType_${bet.type.toLowerCase()}`} />
                {` / ${bet.odds.length}`}
                <span>
                    <span className={styles.label}>
                        <Translate tid="betSlip_history_id" namespace={TranslationScopes.BetSlip} />
                        :{Chars.nbsp}
                    </span>
                    {bet.short_id}
                </span>
            </div>
            <div className={styles.odds}>
                <span className={styles.label}>
                    <Translate tid="betSlip_totalOdds" namespace={TranslationScopes.BetSlip} />
                </span>
                <strong>{bet.odds}</strong>
            </div>
            <div className={styles.stake}>
                <span className={styles.label}>
                    <Translate tid="betSlip_history_stake" namespace={TranslationScopes.BetSlip} />:
                </span>
                <strong>
                    <CurrencyFormatter
                        currency={bet.currency}
                        amount={bet.amount}
                        withCode={false}
                        withSymbol
                    />
                </strong>
            </div>
            <div className={styles.payout}>
                {isStatusAccepted ? (
                    <>
                        <span className={styles.label}>
                            <Translate tid="betSlip_toWin" namespace={TranslationScopes.BetSlip} />:
                        </span>
                        <strong>
                            <CurrencyFormatter
                                currency={bet.currency}
                                amount={bet.possible_win}
                                withCode={false}
                            />
                        </strong>
                    </>
                ) : (
                    <>
                        <span className={styles.label}>
                            <Translate
                                tid="betSlip_history_payout"
                                namespace={TranslationScopes.BetSlip}
                            />
                            :
                        </span>
                        <strong>
                            <CurrencyFormatter
                                currency={bet.currency}
                                amount={bet.payout}
                                withCode={false}
                                withSymbol
                            />
                        </strong>
                    </>
                )}
            </div>
            {(opened || animating) && (
                <div className={styles.details} ref={containerRef}>
                    <div>
                        {bet.bets.map((outcome) => (
                            <OutcomeInfo key={outcome.id} outcome={outcome} isExpress />
                        ))}
                    </div>
                </div>
            )}
            <div
                className={classNames(styles.detailsBtn, (opened || animating) && styles.opened)}
                ref={headerRef}>
                {opened || animating ? (
                    <Translate
                        tid="betSlip_history_hidePicks"
                        namespace={TranslationScopes.BetSlip}
                    />
                ) : (
                    <Translate
                        tid="betSlip_history_seePicks"
                        namespace={TranslationScopes.BetSlip}
                    />
                )}
            </div>
        </div>
    );
};

const BetSlipHistoryBlock: FC = () => {
    const { accountModalRef } = useContext(ModalsContext);
    const router = useRouter();
    const { betsState, filter, setFilter } = useTickets({
        pagination: {
            items_per_page: 20,
        },
        filter: {
            status: TICKET_STATUS.active,
        },
    });

    const handleFilterChange = useCallback(
        (val: string) => {
            setFilter((state) => ({
                ...state,
                status: val,
            }));
        },
        [setFilter],
    );

    return (
        <>
            <nav className="main-tabs">
                <div
                    className={classNames(
                        "main-tabs-tab",
                        filter.status === TICKET_STATUS.active && "active",
                    )}
                    onClick={() => handleFilterChange(TICKET_STATUS.active)}>
                    <Translate
                        tid="betSlip_history_tab_active"
                        namespace={TranslationScopes.BetSlip}
                    />
                </div>
                <div
                    className={classNames(
                        "main-tabs-tab",
                        filter.status === TICKET_STATUS.settled && "active",
                    )}
                    onClick={() => handleFilterChange(TICKET_STATUS.settled)}>
                    <Translate
                        tid="betSlip_history_tab_settled"
                        namespace={TranslationScopes.BetSlip}
                    />
                </div>
            </nav>
            <ScrollComponent
                containerClass={styles.rightSide}
                contentClass={styles.rightSideContent}
                showScrollBar>
                <div className={styles.content}>
                    {betsState.loading && (
                        <div className={classNames("loader", styles.spinner)}>
                            <Svg src="/common/desktop/base-icons/spinner.svg" />
                        </div>
                    )}
                    {!betsState.loading && (
                        <>
                            {betsState.bets?.map((bet) =>
                                bet.type === TicketType.Single ? (
                                    <SportSingleBetView
                                        key={bet.id}
                                        outcome={bet.bets[0]}
                                        bet={bet}
                                    />
                                ) : (
                                    <SportOtherBetView key={bet.id} bet={bet} />
                                ),
                            )}
                            {betsState.bets && betsState.bets.length === 0 && (
                                <div className="no-data">
                                    <Translate tid="no_data" /> ...
                                </div>
                            )}
                        </>
                    )}
                </div>
            </ScrollComponent>
            <div className={styles.viewAllBets}>
                <Link
                    href={{
                        pathname: router.pathname,
                        query: {
                            ...router.query,
                            type: RouterQuery.Account,
                            directory: RouterQuery.Bets,
                            subdirectory: RouterQuery.Sport,
                        },
                    }}
                    shallow
                    onClick={() => accountModalRef.current?.open()}>
                    <Translate
                        tid="betSlip_history_viewAll"
                        namespace={TranslationScopes.BetSlip}
                    />
                </Link>
            </div>
        </>
    );
};

export default BetSlipHistoryBlock;
