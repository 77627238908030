import { CSSProperties, FC } from "react";
import classNames from "classnames";
import styles from "./loading.module.sass";

interface ILoadingProps {
    wrapperClassName?: string;
    style?: CSSProperties;
    testId?: string;
}

const Loading: FC<ILoadingProps> = ({ wrapperClassName, style, testId }) => (
    <div
        className={classNames(styles.loading, "loader", wrapperClassName)}
        style={style}
        data-testid={testId || ""}>
        <span />
    </div>
);

export default Loading;
