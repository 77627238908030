import { useState, useEffect, useRef, RefObject } from "react";
import classNames from "classnames";
import { FieldError } from "react-hook-form";
import { Gender, TranslationScopes } from "@finbackoffice/enums";
import { DropdownOption, ITranslateProps } from "@finbackoffice/site-core";
import FadeInAnimation from "../fade-in/FadeInAnimation";
import ScrollComponent from "../scroll/ScrollComponent";
import Translate from "../translate/Translate";
import Loading from "../loading/Loading";
import { Svg } from "../svg/Svg";
import styles from "./dropdown.module.sass";

export const getGenders = (): DropdownOption[] => {
    const result: DropdownOption[] = [];
    Object.values(Gender).forEach((gender) => {
        result.push({ value: gender, label: `userData_gender${gender}` });
    });

    return result;
};

type IOnChangeValue<T extends string, P extends boolean> = P extends true ? T[] : T;

type IProps<T extends string, P extends boolean = false> = {
    variant?: string;
    data: DropdownOption[];
    styleClass?: string;
    testId?: string;
    error?: Partial<FieldError> | { type: string; message: string };
    loading?: boolean;
    placeholder?: string | ITranslateProps;
    disabled?: boolean;
    required?: boolean;
    label?: string | ITranslateProps;
    multiple?: P;
    renderItem?: (item: DropdownOption) => JSX.Element;
    onChange: (val: IOnChangeValue<T, P>) => void;
    selected?: P extends true ? T[] : T;
};

const Dropdown = <T extends string, P extends boolean = false>({
    selected,
    data,
    styleClass,
    onChange,
    placeholder,
    error,
    loading,
    disabled,
    required,
    label,
    variant = "bottom",
    multiple,
    testId = "",
    renderItem,
}: IProps<T, P>) => {
    const [opened, setOpened] = useState<boolean>(false);
    const dropdownRef: RefObject<HTMLDivElement> = useRef(null);

    useEffect(() => {
        const outsideClickHandler = (event: any) => {
            if (dropdownRef && !dropdownRef.current?.contains(event.target)) {
                setOpened(false);
            }
        };

        if (opened) {
            document.addEventListener("mousedown", outsideClickHandler);
        } else {
            document.removeEventListener("mousedown", outsideClickHandler);
        }

        return () => {
            document.removeEventListener("mousedown", outsideClickHandler);
        };
    }, [opened]);

    const onClickHandler = (value: string) => {
        if (multiple) {
            onChange([value] as IOnChangeValue<T, P>);
        } else if (value !== selected) {
            onChange(value as IOnChangeValue<T, P>);
        }

        setOpened(false);
    };

    const onCheckboxlClick = (val: string) => {
        const index: number = Array.isArray(selected) ? selected?.indexOf(val as T) : -1;
        if (index !== -1) {
            const tempSelected: string[] = selected ? [...selected] : [];
            tempSelected.splice(index, 1);

            onChange(tempSelected as IOnChangeValue<T, P>);
        } else if (selected?.[0]) {
            onChange([...selected, val] as IOnChangeValue<T, P>);
        } else {
            onChange([val] as IOnChangeValue<T, P>);
        }
    };

    const selectedValue = data?.find((item) =>
        multiple ? item.value === selected?.[0] : item.value === selected,
    );

    return (
        <>
            {label && (
                <span className={styles.label}>
                    <Translate
                        tid={typeof label === "string" ? label : label.tid}
                        namespace={
                            typeof label !== "string" ? label.namespace : TranslationScopes.Common
                        }
                    />
                </span>
            )}
            <div
                data-testid={testId}
                ref={dropdownRef}
                className={classNames(
                    styles.dropdown,
                    styleClass,
                    disabled && styles.disabled,
                    required && "required",
                    error && styles.fieldError,
                )}>
                <div onClick={() => !loading && !disabled && setOpened(!opened)}>
                    {loading ? (
                        <Loading wrapperClassName={styles.dropdownLoader} />
                    ) : selectedValue ? (
                        <>
                            <span>
                                {selectedValue.iconClass && (
                                    <i className={selectedValue.iconClass} />
                                )}
                                {selectedValue.svgSrc && (
                                    <Svg
                                        src={selectedValue.svgSrc}
                                        wrapper="span"
                                        className="svg-icon"
                                    />
                                )}
                                <Translate
                                    tid={
                                        typeof selectedValue.label === "string"
                                            ? selectedValue.label
                                            : selectedValue.label.tid
                                    }
                                    namespace={
                                        typeof selectedValue.label !== "string"
                                            ? selectedValue.label.namespace
                                            : TranslationScopes.Common
                                    }
                                />
                            </span>
                            {multiple && (selected?.length || 0) > 1 && (
                                <i className={styles.selectedCount}>{`+${
                                    (selected?.length || 0) - 1
                                }`}</i>
                            )}
                        </>
                    ) : (
                        <span className={styles.placeholder}>
                            {placeholder ? (
                                <Translate
                                    tid={
                                        typeof placeholder === "string"
                                            ? placeholder
                                            : placeholder.tid
                                    }
                                    namespace={
                                        typeof placeholder !== "string"
                                            ? placeholder.namespace
                                            : TranslationScopes.Common
                                    }
                                />
                            ) : (
                                <Translate tid="base_dropdown_placeholder" />
                            )}
                        </span>
                    )}
                </div>

                {error && (
                    <span className={classNames(styles.error, "field-error")}>{error.message}</span>
                )}
                {opened && (
                    <FadeInAnimation>
                        <section className={classNames(styles.dropdownList, styles[variant])}>
                            <ScrollComponent containerClass={styles.dropDownContainer}>
                                <ul>
                                    {data.map((item) => (
                                        <li
                                            key={item.value}
                                            value={item.value}
                                            className={classNames(
                                                (multiple
                                                    ? selected?.indexOf(item.value as T) !== -1
                                                    : selected === item.value) && "selected-li",
                                            )}
                                            onClick={() => onClickHandler(item.value)}
                                            role="presentation">
                                            {multiple && item.value && (
                                                <label
                                                    htmlFor={`checkbox_${item.value}`}
                                                    className={classNames(
                                                        "ui-checkbox",
                                                        styles.checkbox,
                                                        selected &&
                                                            selected.indexOf(item.value as T) !==
                                                                -1 &&
                                                            "checked",
                                                    )}>
                                                    <input
                                                        type="checkbox"
                                                        id={`checkbox_${item.value}`}
                                                    />
                                                    <i
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            e.preventDefault();
                                                            onCheckboxlClick(item.value);
                                                        }}
                                                    />
                                                </label>
                                            )}
                                            {item.iconClass && <i className={item.iconClass} />}
                                            {item.svgSrc && (
                                                <Svg
                                                    src={item.svgSrc}
                                                    wrapper="span"
                                                    className="svg-icon"
                                                />
                                            )}
                                            {item.innerHtml}
                                            {renderItem ? (
                                                renderItem(item)
                                            ) : (
                                                <Translate
                                                    tid={
                                                        typeof item.label === "string"
                                                            ? item.label
                                                            : item.label.tid
                                                    }
                                                    namespace={
                                                        typeof item.label !== "string"
                                                            ? item.label.namespace
                                                            : TranslationScopes.Common
                                                    }
                                                />
                                            )}
                                        </li>
                                    ))}
                                </ul>
                            </ScrollComponent>
                        </section>
                    </FadeInAnimation>
                )}
            </div>
        </>
    );
};
export default Dropdown;
