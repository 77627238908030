import { FC, memo, useContext, useMemo } from "react";
import classNames from "classnames";
import { ISportFeedResponse } from "@finbackoffice/websocket-client";
import { useCollapseMargin } from "@finbackoffice/site-core";
import { useSocketStorage, FeedTypeEnum } from "hooks";
import { PrematchContext } from "contexts";
import SportIcon from "components/base/sport-icon/SportIcon";
import styles from "./prematch-sidebar-sport.module.sass";
import PrematchSidebarCategoriesContainer from "./categories/PrematchSidebarCategoriesContainer";

interface IProps {
    sport: ISportFeedResponse;
    openedByDefault: boolean;
}

const PrematchSidebarSport: FC<IProps> = ({ sport, openedByDefault }) => {
    const { getCategories } = useSocketStorage(FeedTypeEnum.prematch);
    const { opened, animating, headerRef, containerRef } = useCollapseMargin(openedByDefault);
    const { tournaments } = useContext(PrematchContext);

    const selected: boolean = useMemo(
        () => !!tournaments.find((item) => sport.id === item.sportId),
        [sport.id, tournaments],
    );

    return (
        <li className={styles.prematchBarItem} data-testid="sidebar-prematch-sport-item-container">
            <div
                className={classNames(
                    "sport-item-header",
                    styles.sport,
                    opened && styles.opened,
                    selected && styles.selected,
                )}
                data-testid="sidebar-prematch-sport-item"
                data-isopened={opened}
                ref={headerRef}>
                <SportIcon sportId={sport.id} />

                <span>{sport.name}</span>
                <span className={styles.gameCount}>{sport.games_count}</span>
            </div>
            {(opened || animating) && (
                <PrematchSidebarCategoriesContainer
                    sportId={sport.id}
                    sportSlug={sport.slug}
                    categories={getCategories(sport.id)}
                    sportOpenedByDefault={openedByDefault}
                    ref={containerRef}
                />
            )}
        </li>
    );
};

export default memo(PrematchSidebarSport);
