import { IMaxTicketAmountResponse } from "@finbackoffice/clientbff-client";
import { IBetRequest } from "@finbackoffice/clientbff-client/dist/interface";
import {
    BetSlipContext,
    ClientBFFContext,
    ExchangeRatesContext,
    UserAccountContext,
} from "@finbackoffice/site-core";
import { useCallback, useContext, useState } from "react";

export const useLimits = () => {
    const client = useContext(ClientBFFContext);
    const { betType } = useContext(BetSlipContext);
    const { currentWallet } = useContext(UserAccountContext);
    const [isLoadingBetMax, setIsLoadingBetMax] = useState(false);
    const { minBet, maxBet, maxWin } = useContext(ExchangeRatesContext);

    const getPossibleBetMax = useCallback(
        (outcomeValue: number) => {
            const possibleMaxBet = maxWin / outcomeValue;

            if (possibleMaxBet <= maxBet) {
                return possibleMaxBet;
            }

            return maxBet;
        },
        [maxBet, maxWin],
    );

    const requestBetMax = useCallback(
        async (bets: IBetRequest[]) => {
            if (currentWallet?.id) {
                let response: IMaxTicketAmountResponse | null = null;
                setIsLoadingBetMax(true);

                try {
                    response = await client.calculateMaxBet({
                        bets,
                        type: betType,
                        wallet_id: currentWallet?.id,
                    });

                    setIsLoadingBetMax(false);
                    return response;
                } catch (error) {
                    console.error(error);
                    // TODO: add auth error message
                    // TODO: add betmax error message
                }
            }
            return null;
        },
        [betType, client, currentWallet?.id],
    );

    return {
        getPossibleBetMax,
        siteMinBet: minBet,
        siteMaxWin: maxWin,
        requestBetMax,
        isLoadingBetMax,
    };
};
