import { sortAlphabeticallyByProp, CasinoGameTypeEnum } from "@finbackoffice/fe-core";
import {
    createContext,
    Dispatch,
    FC,
    PropsWithChildren,
    SetStateAction,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { CasinoFavoritesContext, ClientBFFContext } from "@finbackoffice/site-core";
import { ICategorySummaryResponse } from "@finbackoffice/clientbff-client";
import { useRouter } from "next/router";
import { CasinoGameTag } from "@finbackoffice/enums";
import { RouteNames } from "utils/constants";

type ICasinoToolsContext = {
    gameTypes: CasinoGameTypeEnum[];
    providerOptions: ICategorySummaryResponse[];
    openProviderPanel: boolean;
    setOpenProviderPanel: Dispatch<SetStateAction<boolean>>;
};

export const CasinoToolsContext = createContext<ICasinoToolsContext>(null as any);

type Props = {
    initialGameTypes: CasinoGameTypeEnum[];
};

export const CasinoToolsProvider: FC<Props & PropsWithChildren> = ({
    children,
    initialGameTypes,
}) => {
    const router = useRouter();
    const isFavoritesPage = router.pathname.includes(`/${RouteNames.FAVORITES}`);
    const client = useContext(ClientBFFContext);
    const [providerOptions, setProviderOptions] = useState<ICategorySummaryResponse[]>([]);
    const [openProviderPanel, setOpenProviderPanel] = useState(true);
    const { favoriteGamesState } = useContext(CasinoFavoritesContext);

    const removeQuery = useCallback(
        (key: string) => {
            const query = { ...router.query };
            delete query[key];
            router.push({ query }, undefined, { shallow: true });
        },
        [router],
    );

    useEffect(() => {
        const loadProviderOptions = async () => {
            let response = await client.getCasinoGameSummaryByCategory({
                mobile: false,
                types: initialGameTypes,
                tag: router.query.tag as CasinoGameTag,
                megaways: router.query.filter === "megaways",
                bonusbuy: router.query.filter === "bonusbuy",
                has_jackpot: router.query.filter === "has_jackpot",
            });

            if (
                router.query.provider &&
                response.find((item) => item.category === router.query.provider) === undefined
            ) {
                removeQuery("provider");
            }

            if (isFavoritesPage) {
                const availableCategories = favoriteGamesState.games.map((game) => game.category);
                response = response.filter((item) => availableCategories.includes(item.category));
                response.forEach(
                    (item) =>
                        (item.total_games = availableCategories.filter(
                            (provider) => provider === item.category,
                        ).length),
                );
            }
            setProviderOptions(response.sort((a, b) => sortAlphabeticallyByProp("category")(a, b)));
        };
        if (!!initialGameTypes.length) {
            try {
                loadProviderOptions();
            } catch (error) {
                console.error(error);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router.query.tag, router.query.filter, router.pathname]);

    const value = useMemo(
        () => ({
            gameTypes: initialGameTypes,
            openProviderPanel,
            setOpenProviderPanel,
            providerOptions,
        }),
        [initialGameTypes, openProviderPanel, providerOptions],
    );

    return <CasinoToolsContext.Provider value={value}>{children}</CasinoToolsContext.Provider>;
};
