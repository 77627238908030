import { FC, memo, useMemo } from "react";
import { Settings } from "react-slick";
import Link from "next/link";
import { useRouter } from "next/router";
import { TranslationScopes } from "@finbackoffice/enums";
import { ITopLeagueFeedResponse } from "@finbackoffice/websocket-client";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import { usePrematchTournament } from "hooks";
import Loading from "components/base/loading/Loading";
import Translate from "components/base/translate/Translate";
import SlickSlider from "components/base/slick-slider/SlickSlider";
import { RouteNames } from "utils/constants";
import SportIcon from "components/base/sport-icon/SportIcon";
import HomeTournamentGameItem from "./game/HomeTournamentGameItem";
import styles from "./home-tournament-item.module.sass";

type Props = {
    tournament: ITopLeagueFeedResponse;
    settings?: Settings;
};

const HomeTournamentItem: FC<Props> = ({ tournament, settings }) => {
    const router = useRouter();
    const { games, loading } = usePrematchTournament(tournament.id, false);

    const sliderSettings: Settings = useMemo(
        () => ({
            dots: false,
            infinite: false,
            variableWidth: true,
            speed: 1300,
            slidesToShow: 3,
            slidesToScroll: 2,
            arrows: true,
            responsive: [
                {
                    breakpoint: 1600,
                    settings: {
                        slidesToShow: 2,
                    },
                },
                {
                    breakpoint: 1050,
                    settings: {
                        slidesToShow: 1,
                    },
                },
            ],
            ...settings,
        }),
        [settings],
    );

    return (
        <FadeInAnimation>
            <div className={styles.homePageItem}>
                <div className={styles.header}>
                    <h2 className={styles.headerSport}>
                        {Boolean(games.length) && (
                            <>
                                <SportIcon sportId={games[0].sport_id} />
                                {games[0].sport_name}
                            </>
                        )}
                    </h2>
                    <Link
                        href={{
                            pathname: `/${RouteNames.SPORT}/${RouteNames.PREMATCH}/[[...prematchGameRoutes]]`,
                            query: {
                                ...router.query,
                                prematchGameRoutes: [
                                    tournament.sport_slug,
                                    tournament.category_id.toString(),
                                    tournament.id.toString(),
                                ],
                            },
                        }}>
                        <Translate
                            tid="home_top_events"
                            namespace={TranslationScopes.Home}
                            replace={{ tournament_name: tournament.name || "" }}
                        />
                    </Link>
                </div>
                {loading && <Loading />}
                <div className={styles.homeGames}>
                    <div>
                        <SlickSlider settings={sliderSettings}>
                            {games.map((game, index) => (
                                <HomeTournamentGameItem key={game.id} game={game} index={index} />
                            ))}
                        </SlickSlider>

                        {/* <div className={styles.homeGameItem}>
                            <div className={styles.homeGameItemHeader}>
                                <div>
                                    <Svg
                                        src="/common/regions/30.svg"
                                        wrapper="span"
                                        className="svg-region-icon"
                                        loader
                                    />
                                    Germany / Bundesliga
                                </div>
                                <span className={styles.liveGameLabel}>Live</span>
                            </div>
                            live soccer
                            <div className={classNames(styles.soccer, styles.liveSoccer)}>
                                <div className={styles.soccerTeam}>
                                    <TeamIcon sportId={1} teamId={22} />
                                    <span>Borussia Dortmund</span>
                                </div>
                                <div className={styles.soccerScore}>
                                    <span>
                                        <span>0</span>:<span>1</span>
                                    </span>
                                    Halftime
                                </div>
                                <div className={styles.soccerTeam}>
                                    <TeamIcon sportId={1} teamId={31} />
                                    <span>Eintracht Francfurt</span>
                                </div>
                            </div>
                            <div className={styles.homeGameItemMarket}>outcome</div>
                        </div> */}

                        {/* <div className={styles.homeGameItem}>
                            <div className={styles.homeGameItemHeader}>
                                <div>
                                    <Svg
                                        src="/common/regions/30.svg"
                                        wrapper="span"
                                        className="svg-region-icon"
                                        loader
                                    />
                                    Germany / Bundesliga
                                </div>
                            </div>
                            prematch soccer
                            <div className={styles.soccer}>
                                <div className={styles.soccerTeam}>
                                    <TeamIcon sportId={1} teamId={22} />
                                    <span>Borussia Dortmund</span>
                                </div>
                                <div className={styles.soccerScore}>
                                    <span>22:08.22</span>
                                    23:30
                                </div>
                                <div className={styles.soccerTeam}>
                                    <TeamIcon sportId={1} teamId={31} />
                                    <span>Eintracht Francfurt</span>
                                </div>
                            </div>
                            <div className={styles.homeGameItemMarket}>outcome</div>
                        </div> */}
                    </div>
                </div>
            </div>
        </FadeInAnimation>
    );
};

export default memo(HomeTournamentItem);
