import { useContext, useEffect, useState } from "react";
import { SportTypes } from "@finbackoffice/fe-core";
import { LocaleContext } from "@finbackoffice/site-core";
import { SIRWidgetContext } from "contexts";

declare function SIR(
    action: WidgetActionEnum,
    selector: string,
    widgetType?: WidgetTypeEnum,
    props?: SirWidgetProps,
    onInit?: (...args: unknown[]) => any,
): any;

type OnTrackParam1 = "data_change" | "odds_click" | "social_share" | "license_error";
type OnTrackParam2 = Record<string, unknown>;

interface SirWidgetProps {
    layout?: "topdown";
    collapseTo?: "momentum";
    tabsPosition?: "top" | "disable";
    matchId: string;
    onTrack: (param1: OnTrackParam1, param2: OnTrackParam2) => void;
    silent: boolean;
    expanded?: boolean;
    branding?: {
        tabs: { option: string; variant: string };
    };
    disablePitchNoise?: boolean;
    disablePitchSpotlights?: boolean;
    scoreboard?: string;
    disableComponents?: boolean;
    detailedScoreboard?: "disable";
    activeSwitcher?: "scoreDetails";
    forceTeamInvert?: boolean;
    disableWidgetHeader?: boolean;
}

export enum WidgetActionEnum {
    ADD_WIDGET = "addWidget",
    REMOVE_WIDGET = "removeWidget",
}

export enum WidgetTypeEnum {
    LMT_PLUS = "match.lmtPlus",
    LMT_COMPACT = "match.lmtCompact",
}

export const useSIRWidget = () => {
    const { locale } = useContext(LocaleContext);
    const { checkIfTeamInverted } = useContext(SIRWidgetContext);
    const [isLicenced, setIsLicenced] = useState(true);
    const [hideContent, setHideContent] = useState(false);

    useEffect(() => {
        if (!document.getElementById("sir-widget-script")) {
            const script = document.createElement("script");
            script.id = "sir-widget-script";
            script.appendChild(
                document.createTextNode(`(function(a,b,c,d,e,f,g,h,i){a[e]||(i=a[e]=function(){(a[e].q=a[e].q||[]).push(arguments)},i.l=1*new Date,i.o=f,
                    g=b.createElement(c),h=b.getElementsByTagName(c)[0],g.async=1,g.src=d,g.setAttribute("n",e),h.parentNode.insertBefore(g,h)
                    )})(window,document,"script", "https://widgets.sir.sportradar.com/7f58e65fe4ac1587c6f000924fbb13d4/widgetloader", "SIR", {
                        theme : false,
                        language: "${locale}"
                    })`),
            );

            document.body.appendChild(script);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function initLmtPlusWidget(
        sportId: number,
        regionId: number,
        matchId: string,
        selector = "",
        onInit?: (...args: unknown[]) => any,
        onError?: (...args: unknown[]) => any,
    ) {
        SIR(
            WidgetActionEnum.ADD_WIDGET,
            `${selector} .sr-widget-lmtplus`,
            WidgetTypeEnum.LMT_PLUS,
            {
                layout: "topdown",
                collapseTo: "momentum",
                tabsPosition: "top",
                expanded: true,
                ...(checkIfTeamInverted?.(sportId, regionId) ? { forceTeamInvert: true } : {}),
                matchId,
                onTrack: (param1: OnTrackParam1) => {
                    if (param1 === "license_error") {
                        setIsLicenced?.(false);
                        setHideContent(true);
                        onError?.();
                    }
                },
                silent: true,
            },
            onInit,
        );
    }

    function initLmtPlusModalWidget(
        sportId: number,
        regionId: number,
        matchId: string,
        selector = "",
        onInit?: (...args: unknown[]) => any,
        onError?: (...args: unknown[]) => any,
    ) {
        SIR(
            WidgetActionEnum.ADD_WIDGET,
            `${selector} .sr-widget-lmtplus`,
            WidgetTypeEnum.LMT_PLUS,
            {
                branding: {
                    tabs: { option: "icon", variant: "fullWidth" },
                },
                disablePitchNoise: true,
                disablePitchSpotlights: true,
                layout: "topdown",
                scoreboard: "extended",
                collapseTo: "momentum",
                activeSwitcher: "scoreDetails",
                disableWidgetHeader: false,
                ...(checkIfTeamInverted?.(sportId, regionId) ? { forceTeamInvert: true } : {}),
                matchId,
                onTrack: (param1: OnTrackParam1) => {
                    if (param1 === "license_error") {
                        setIsLicenced?.(false);
                        setHideContent(true);
                        onError?.();
                    }
                },
                silent: true,
            },
            onInit,
        );
    }

    function initLmtCompactWidget(
        sportId: number,
        regionId: number,
        matchId: string,
        selector = "",
        onInit?: (...args: unknown[]) => any,
        onError?: (...args: unknown[]) => any,
    ) {
        SIR(
            WidgetActionEnum.ADD_WIDGET,
            `${selector} .sr-widget-lmtplus`,
            WidgetTypeEnum.LMT_PLUS,
            {
                disableComponents: true,
                detailedScoreboard: "disable",
                tabsPosition: "disable",
                ...(checkIfTeamInverted?.(sportId, regionId) ? { forceTeamInvert: true } : {}),
                ...([SportTypes.BASEBALL].includes(sportId)
                    ? {
                          scoreboard: "extended",
                      }
                    : {}),
                matchId,
                onTrack: (param1: OnTrackParam1, param2: OnTrackParam2) => {
                    if (
                        param1 === "license_error" ||
                        (param1 === "data_change" && !!param2.error)
                    ) {
                        setIsLicenced?.(false);
                        setHideContent(true);
                        onError?.();
                    }
                },
                silent: true,
            },
            onInit,
        );
        SIR(
            WidgetActionEnum.ADD_WIDGET,
            `${selector} .sr-widget-compact`,
            WidgetTypeEnum.LMT_COMPACT,
            {
                ...(checkIfTeamInverted?.(sportId, regionId) ? { forceTeamInvert: true } : {}),
                matchId,
                onTrack: (param1: any) => {
                    if (param1 === "license_error") {
                        setIsLicenced?.(false);
                        setHideContent(true);
                        onError?.();
                    }
                },
                silent: true,
            },
            onInit,
        );
    }

    function removeWidget(selector: string) {
        SIR(WidgetActionEnum.REMOVE_WIDGET, selector);
    }

    return {
        initLmtPlusWidget,
        initLmtCompactWidget,
        initLmtPlusModalWidget,
        removeWidget,
        isLicenced,
        hideContent,
    };
};
