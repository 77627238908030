import { FC, SyntheticEvent, useContext, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { IMarketOutcomeFeedResponse } from "@finbackoffice/clientbff-client";
import { OddFormatter } from "@finbackoffice/fe-core";
import { BetSlipContext, ConfigContext, usePrevious } from "@finbackoffice/site-core";
import { MarketStatus, MarketOutcomeStatus } from "@finbackoffice/enums";
import styles from "./outcome.module.sass";

interface IOutcomeProps {
    type?: "default" | "handicap";
    outcome?: IMarketOutcomeFeedResponse | null;
    onOutcomeClick?: (outcome: IMarketOutcomeFeedResponse) => void;
    marketStatus?: string;
    variant?: "mainMarket";
}

const Outcome: FC<IOutcomeProps> = ({
    outcome,
    onOutcomeClick,
    marketStatus,
    variant,
    type = "default",
}) => {
    const { isOutcomeSelected } = useContext(BetSlipContext);
    const { siteCurrentOddFormat } = useContext(ConfigContext);
    const [arrowType, setArrowType] = useState("");

    const prevOdds = usePrevious(outcome?.odds) ?? "";
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);
    const isOutcomeDisabled =
        marketStatus === MarketStatus.OnHold ||
        (outcome && outcome.status === MarketOutcomeStatus.NotActive) ||
        !outcome;
    const selected = useMemo(
        () => (outcome ? isOutcomeSelected(outcome.id) : false),
        [isOutcomeSelected, outcome],
    );

    const onClickHandler = (event: SyntheticEvent) => {
        if (!isOutcomeDisabled) {
            event.stopPropagation();
            event.preventDefault();

            onOutcomeClick?.(outcome);
        }
    };

    useEffect(() => {
        if (!outcome) {
            return;
        }

        if (prevOdds && outcome) {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
            if (parseFloat(prevOdds) < parseFloat(outcome.odds)) {
                setArrowType("increasedIcon");
            } else if (parseFloat(prevOdds) > parseFloat(outcome.odds)) {
                setArrowType("decreasedIcon");
            }

            timeoutRef.current = setTimeout(() => {
                setArrowType("");
            }, 10000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [outcome?.odds]);

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    const renderValue = useMemo(() => {
        if (!outcome) return;
        return (
            <span className={styles.outcomeItemValue}>
                <i className={styles[arrowType]} />{" "}
                {OddFormatter.format(parseFloat(outcome.odds), siteCurrentOddFormat)}
            </span>
        );
    }, [arrowType, outcome, siteCurrentOddFormat]);

    const renderName = useMemo(() => {
        if (!outcome) return;

        if (type === "handicap" && outcome.name) {
            const rgx = /\((.+?)\)/;
            const numberBetweenBrackets = rgx.exec(outcome.name);
            const restName = outcome.name.substring(0, numberBetweenBrackets?.index).trim();
            return (
                <span title={outcome.name ?? undefined}>
                    <span>{restName}</span>
                    <span>{numberBetweenBrackets?.[0]}</span>
                </span>
            );
        }

        return (
            <span className={styles.outcomeItemName} title={outcome.name ?? undefined}>
                {outcome.name}
            </span>
        );
    }, [outcome, type]);

    return (
        <div
            className={classNames(
                styles.outcomeItem,
                variant && styles[variant],
                isOutcomeDisabled && styles.disabled,
                selected && styles.selected,
            )}
            data-testid={`outcome-${isOutcomeDisabled ? "disabled" : "enabled"}`}
            onClick={onClickHandler}>
            {outcome ? (
                <>
                    {renderName}
                    {renderValue}
                </>
            ) : (
                "- -"
            )}
        </div>
    );
};
export default Outcome;
