import { useState, useEffect, useCallback, useContext, useMemo, CSSProperties, FC } from "react";
import Image from "next/image";
import classNames from "classnames";
import { LocaleContext, useRuntimeConfig } from "@finbackoffice/site-core";
import { ComponentProps } from "react";

type ImageProps = Omit<ComponentProps<typeof Image>, "src">;

export interface ImgProps extends ImageProps {
    source: string;
    alt: string;
    fallbackSrc?: string;
    wrapperClassName?: string;
    style?: CSSProperties;
    onFallback?: () => void;
}

const Img: FC<ImgProps> = ({
    source,
    fallbackSrc,
    wrapperClassName,
    alt,
    style,
    onFallback,
    ...rest
}) => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const { locale } = useContext(LocaleContext);
    const [imgSrc, setImgSrc] = useState(source);
    const [ready, setReady] = useState(false);

    const handleError = useCallback(() => {
        if (fallbackSrc) {
            setImgSrc(fallbackSrc);
            onFallback?.();
        } else if (source.includes(locale)) {
            setImgSrc(source.replace(locale, COMMON_SITE_CONFIGS.defaultLocale));
        }
    }, [fallbackSrc, source, locale, onFallback, COMMON_SITE_CONFIGS.defaultLocale]);

    const onLoadCallback = useCallback(() => setReady(true), []);

    useEffect(() => {
        setImgSrc(source);
    }, [source]);

    const inlineStyles = useMemo(() => {
        const styles = { ...style, opacity: ready ? 1 : 0 };

        if (!rest.priority) {
            styles.transition = "opacity 0.2s";
        }

        return style;
    }, [ready, rest.priority, style]);

    return (
        <span className={classNames("imageNext", wrapperClassName)}>
            {imgSrc && (
                <Image
                    quality={100}
                    alt={alt}
                    src={imgSrc}
                    onError={handleError}
                    style={inlineStyles}
                    unoptimized
                    {...rest}
                    onLoad={onLoadCallback}
                />
            )}
        </span>
    );
};

export default Img;
