import { FC, useCallback, useContext, memo, SyntheticEvent } from "react";
import { useRouter } from "next/router";
import { IEventFeedResponse } from "@finbackoffice/websocket-client";
import { SportTypes } from "@finbackoffice/fe-core";
import { BetSlipContext, FavoriteEventsContext, useMainMarketOdds } from "@finbackoffice/site-core";
import classNames from "classnames";
import { IMarketOutcomeFeedResponse } from "@finbackoffice/clientbff-client";
import { MarketOutcomeStatus } from "@finbackoffice/enums";
import { FeedTypeEnum, useDate, useSocketStorage } from "hooks";
import ActiveLink from "components/base/active-link/ActiveLink";
import Outcome from "components/pages/sport/base/outcome/Outcome";
import { MarketUpdatesContext } from "contexts";
import { RouteNames } from "utils/constants";
import { MultiViewContext } from "contexts/MultiViewProvider";
import styles from "./live-sidebar-game.module.sass";

interface IProps {
    game: IEventFeedResponse;
}

const LiveSidebarGame: FC<IProps> = ({ game }) => {
    const { getSportById } = useSocketStorage(FeedTypeEnum.live);
    const { addBetItem, removeBetItem, isOutcomeSelected } = useContext(BetSlipContext);
    const { addFav, removeFav, isFav } = useContext(FavoriteEventsContext);
    const router = useRouter();
    const { formatDate } = useDate();
    const { gameIds, addGame, removeGame } = useContext(MultiViewContext);
    const { subscribeMarket, unsubscribeMarket } = useContext(MarketUpdatesContext);

    const gameId = game.id;
    const homeName = game.home_team?.name || "";
    const awayName = game.away_team?.name || "";
    const sportId = game.sport_id;
    const {
        mainOdds,
        mainMarketName,
        mainMarketStatus,
        mainMarketTemplateId,
        mainMarketId,
        columnCount,
    } = useMainMarketOdds(sportId, game.markets);

    const onOutcomeClick = useCallback(
        (outcome: IMarketOutcomeFeedResponse | null) => {
            if (mainMarketId && mainMarketStatus && outcome) {
                if (!isOutcomeSelected(outcome.id)) {
                    addBetItem({
                        gameId,
                        marketGroupId: mainMarketTemplateId?.toString() || "",
                        marketId: mainMarketId,
                        outcomeId: outcome.id,
                        team1Name: homeName,
                        team2Name: awayName,
                        marketName: mainMarketName,
                        marketStatus: mainMarketStatus,
                        outcomeName: outcome.name ?? "",
                        outcomeValue: outcome.odds,
                        outcomeStatus: MarketOutcomeStatus.Active,
                        outcomeInitValue: outcome.odds,
                        stake: "",
                        loading: false,
                        accepted: false,
                    });
                    subscribeMarket(gameId, mainMarketId, outcome.id);
                } else {
                    removeBetItem(outcome.id);
                    unsubscribeMarket(outcome.id);
                }
            }
        },
        [
            mainMarketId,
            isOutcomeSelected,
            gameId,
            mainMarketTemplateId,
            homeName,
            awayName,
            mainMarketName,
            mainMarketStatus,
            subscribeMarket,
            unsubscribeMarket,
            addBetItem,
            removeBetItem,
        ],
    );

    const onGameItemClickHandler = (event: any): void => {
        if (router.pathname.includes(RouteNames.MULTI_VIEW)) {
            event.stopPropagation();
            event.preventDefault();
            if (gameIds.indexOf(game.id) === -1) {
                if (gameIds.length < 6) {
                    addGame(game.id);
                }
            } else {
                removeGame(game.id);
            }
        }
    };

    const handleFavClick = (event: SyntheticEvent) => {
        event.preventDefault();
        if (isFav(game.id)) {
            removeFav(game.id);
        } else {
            addFav({
                id: game.id,
                timestamp: game.scheduled_at ? new Date(game.scheduled_at).valueOf() : 0,
                sport_id: game.sport_id,
                tournament_id: game.tournament_id,
            });
        }
    };

    return (
        <ActiveLink
            href={`/${RouteNames.SPORT}/${RouteNames.LIVE}/${getSportById(game.sport_id)?.slug}/${
                game.category_id
            }/${game.id}`}
            activeClassName={styles.active}
            shallow
            className={classNames(styles.eventItem, {
                [styles.multiViewSelected]:
                    router.pathname.includes(RouteNames.MULTI_VIEW) &&
                    gameIds.indexOf(game.id) !== -1,
            })}
            data-testid="sidebar-live-game-item"
            onClick={onGameItemClickHandler}>
            <>
                <div className={styles.team}>
                    <span className={styles.teamName}>{game.home_team?.name}</span>
                    <span className={styles.score}>{game.scoreboard?.home_score}</span>
                </div>
                <div className={styles.team}>
                    <span className={styles.teamName}>{game.away_team?.name}</span>
                    <span className={styles.score}>{game.scoreboard?.away_score}</span>
                </div>
                <div className={styles.matchStatus}>
                    {game.scheduled_at && formatDate(new Date(game.scheduled_at), "HH:mm / ")}
                    {game.match_status}
                    {game.sport_id === SportTypes.BASKETBALL &&
                        game.scoreboard?.event_clock &&
                        ` / ${game.scoreboard.event_clock.remaining_time_in_period}`}
                    <span className={styles.marketsCount}>{`+${game.markets_count}`}</span>
                    <i
                        className={classNames(styles.favorite, { [styles.active]: isFav(game.id) })}
                        onClick={handleFavClick}
                    />
                </div>
                <div className={styles[`marketColumn-${columnCount}`]}>
                    <div className={styles.market}>
                        <Outcome
                            outcome={mainOdds?.homeOutcome}
                            marketStatus={mainMarketStatus}
                            onOutcomeClick={(outcome) => onOutcomeClick(outcome)}
                        />
                        {columnCount === "three_columns" && (
                            <Outcome
                                outcome={mainOdds?.drawOutcome}
                                marketStatus={mainMarketStatus}
                                onOutcomeClick={(outcome) => onOutcomeClick(outcome)}
                            />
                        )}
                        <Outcome
                            outcome={mainOdds?.awayOutcome}
                            marketStatus={mainMarketStatus}
                            onOutcomeClick={(outcome) => onOutcomeClick(outcome)}
                        />
                    </div>
                </div>
            </>
        </ActiveLink>
    );
};

export default memo(LiveSidebarGame);
