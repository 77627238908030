import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { IClientError, IEventFeedResponse } from "@finbackoffice/websocket-client";
import { TemplateIdsMap, MarketGroupIds, sortSetWinnerMarkets } from "@finbackoffice/fe-core";
import {
    BetSlipContext,
    convertMarketsIntoMarketGroups,
    IGameData,
    sortMarketGroups,
    useWebsocketUpdates,
    WebsocketContext,
} from "@finbackoffice/site-core";
import { IMarketOutcomeFeedResponse } from "@finbackoffice/clientbff-client";
import { MarketOutcomeStatus, MarketStatus } from "@finbackoffice/enums";
import { MarketUpdatesContext } from "contexts";
import { RouteNames } from "utils/constants";
import { useGetCurrentFeedType } from "./useGetCurrentFeed";

const excludeGroups = (ids: number[]) => {
    return MarketGroupIds.filter((id) => !ids.includes(id));
};

export const useGame = (gameId: number) => {
    const router = useRouter();
    const { addBetItem, removeBetItem, isOutcomeSelected } = useContext(BetSlipContext);
    const { subscribeMarket, unsubscribeMarket } = useContext(MarketUpdatesContext);
    const { socketClient } = useContext(WebsocketContext);
    const feed = useGetCurrentFeedType();
    const [gameData, setGameData] = useState<IGameData | null>(null);

    const sportId = gameData?.sport_id;
    const homeName = gameData?.home_team?.name || "";
    const awayName = gameData?.away_team?.name || "";

    const gameUpdateHandler = useCallback(
        (data: IEventFeedResponse, error: IClientError) => {
            if (!error) {
                setGameData((state) => {
                    let updatedGame: IGameData = { ...data, market_groups: {} };

                    updatedGame.markets = socketClient.mergeEventMarkets(
                        state?.markets || [],
                        updatedGame.markets,
                    );

                    updatedGame.markets?.sort(sortSetWinnerMarkets);

                    updatedGame = convertMarketsIntoMarketGroups(
                        updatedGame,
                        excludeGroups([
                            TemplateIdsMap.QUARTERNUMBER_QUARTER_TOTAL,
                            TemplateIdsMap.QUARTERNUMBER_QUARTER_HANDICAP,
                            TemplateIdsMap.SETNUMBER_SET_POINT_HANDICAPS,
                            TemplateIdsMap.SETNUMBER_SET_TOTAL_POINTS,
                        ]),
                    );

                    updatedGame = sortMarketGroups(updatedGame);

                    return updatedGame;
                });
            } else if (error.statusCode === 404) {
                router.replace({
                    pathname: `/${RouteNames.SPORT}/${feed}`,
                });
            }
        },
        [socketClient, router, feed],
    );

    const payload = useMemo(() => ({ event_id: gameId }), [gameId]);
    useWebsocketUpdates("event", gameUpdateHandler, true, payload);

    useEffect(() => {
        return () => {
            if (gameId) {
                setGameData(null);
            }
        };
    }, [gameId]);

    const getUpdatedFavorites = useCallback(
        (storage: Storage, marketType: string) => {
            if (sportId) {
                if (storage && storage.hasOwnProperty(sportId)) {
                    const index: number = storage[sportId].indexOf(marketType);

                    if (index === -1) {
                        storage[sportId].push(marketType);
                    } else {
                        storage[sportId].splice(index, 1);
                    }
                } else {
                    storage[sportId] = [marketType];
                }
            }

            return storage;
        },
        [sportId],
    );

    const addBet = useCallback(
        (
            marketGroupId: string,
            marketName: string,
            marketStatus: MarketStatus,
            marketId: number,
            outcome: IMarketOutcomeFeedResponse,
        ) => {
            if (!isOutcomeSelected(outcome.id)) {
                addBetItem({
                    gameId,
                    marketGroupId,
                    marketId,
                    outcomeId: outcome.id,
                    team1Name: homeName,
                    team2Name: awayName,
                    marketName,
                    marketStatus,
                    outcomeName: outcome.name ?? "",
                    outcomeValue: outcome.odds,
                    outcomeStatus: MarketOutcomeStatus.Active,
                    outcomeInitValue: outcome.odds,
                    stake: "",
                    loading: false,
                    accepted: false,
                });
                subscribeMarket(gameId, marketId, outcome.id);
            } else {
                removeBetItem(outcome.id);
                unsubscribeMarket(outcome.id);
            }
        },
        [
            awayName,
            isOutcomeSelected,
            gameId,
            homeName,
            subscribeMarket,
            unsubscribeMarket,
            addBetItem,
            removeBetItem,
        ],
    );

    return { gameData, getUpdatedFavorites, addBet };
};
