import { sortLiveTopLeagues } from "@finbackoffice/fe-core";
import {
    useCollapseMargin,
    FavoriteEventsContext,
    FeedContext,
    useRuntimeConfig,
} from "@finbackoffice/site-core";
import { FC, useContext } from "react";
import classNames from "classnames";
import Img from "components/base/img/Img";
import FadeInAnimation from "components/base/fade-in/FadeInAnimation";
import Translate from "components/base/translate/Translate";
import styles from "../sport/live-sidebar-sport.module.sass";
import LiveSidebarTournament from "../sport/categories/tournaments/LiveSidebarTournament";

type Props = {
    onSelect?: () => void;
};

const LiveTopLeagues: FC<Props> = () => {
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const { liveTopLeagues, liveTopLeaguesGamesCount } = useContext(FeedContext);
    const { favLiveLeagues, favLiveLeaguesGameCount } = useContext(FavoriteEventsContext);
    const { opened, animating, headerRef, containerRef } = useCollapseMargin(true);

    if (Boolean(!liveTopLeagues.length) && Boolean(!favLiveLeagues.length)) return null;

    return (
        <>
            <FadeInAnimation>
                <div
                    className={classNames("sport-item-header", styles.sportItem, {
                        [styles.opened]: opened,
                    })}
                    style={{ marginTop: 1 }}
                    ref={headerRef}>
                    <Img
                        source={`${ASSETS_URL}/common/desktop/sport-headers/topleagues-favourites.webp`}
                        width={136}
                        height={30}
                        alt="Top leagues favourites"
                        wrapperClassName={styles.headerBg}
                    />
                    <Img
                        source={`${ASSETS_URL}/common/desktop/base-icons/arrow-white.svg`}
                        alt="arrow"
                        width={10}
                        height={5}
                        wrapperClassName={styles.arrow}
                    />
                    <span>
                        <Translate tid="sidebar_live_topLeagues" />
                    </span>
                    <span>({liveTopLeaguesGamesCount + favLiveLeaguesGameCount})</span>
                </div>
            </FadeInAnimation>
            <div className={styles.topLeaguesContainer}>
                {(opened || animating) && (
                    <div ref={containerRef}>
                        {[...favLiveLeagues, ...liveTopLeagues]
                            .sort(sortLiveTopLeagues)
                            .map((tournament) => (
                                <LiveSidebarTournament
                                    key={tournament.id}
                                    tournament={tournament}
                                    sportId={tournament.sport_id}
                                    eventIds={!tournament.is_top ? tournament.events : undefined}
                                    openedByDefault={false}
                                />
                            ))}
                    </div>
                )}
            </div>
        </>
    );
};

export default LiveTopLeagues;
