import { ICompleteRecoveryRequest } from "@finbackoffice/clientbff-client";
import { RequestError } from "@finbackoffice/fe-core";
import {
    signupValidationSchema,
    ClientBFFContext,
    useTranslation,
    useSignupConfig,
} from "@finbackoffice/site-core";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import { FC, useContext, useState, useCallback } from "react";
import { Controller, useForm } from "react-hook-form";
import { string, ref, InferType } from "yup";
import { SignupInputFieldName } from "@finbackoffice/enums";
import Button from "components/base/button/Button";
import Translate from "components/base/translate/Translate";
import { NotificationContext } from "contexts";
import Input from "components/base/input-field/Input";
import styles from "./forgot-pass.module.sass";

const createNewPasswordValidationSchema = signupValidationSchema.pick(["password"]).shape({
    password_confirm: string().oneOf([ref("password")], "Passwords must match"),
});

export interface ICreateNewPassForm extends InferType<typeof createNewPasswordValidationSchema> {
    password: string;
    password_confirm: string;
}

type IStep2Props = {
    setStep: (val: number) => void;
    code: string;
    id: string;
};

const Step2: FC<IStep2Props> = ({ code, id, setStep }) => {
    const client = useContext(ClientBFFContext);
    const { notice } = useContext(NotificationContext);
    const { t } = useTranslation();
    const [loading, setLoading] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { signupConfigObj: forgotPassConfigObj } = useSignupConfig([
        SignupInputFieldName.Password,
    ]);

    const {
        control,
        handleSubmit,
        formState: { isValid, isSubmitting, errors, isDirty },
    } = useForm({
        mode: "onChange",
        shouldUnregister: true,
        context: {
            configObj: {
                ...forgotPassConfigObj,
                t,
            },
        },
        resolver: yupResolver(createNewPasswordValidationSchema),
    });

    const completeRecovery = useCallback(
        async (data: InferType<typeof createNewPasswordValidationSchema>) => {
            setLoading(true);
            try {
                const req: ICompleteRecoveryRequest = {
                    password: data.password!,
                    code,
                    id,
                };
                await client.completeRecovery(req);
                setStep(3);
            } catch (err: any) {
                const error: RequestError = err.response?.data;

                notice({
                    type: "error",
                    title: error.error,
                    message: typeof error.message === "string" ? error.message : error.message[0],
                });

                setLoading(false);
            }
        },
        [client, code, id, setStep, notice],
    );

    const onSubmit = useCallback(
        (data: InferType<typeof createNewPasswordValidationSchema>) => {
            if (isDirty && isValid && !isSubmitting && !loading) {
                completeRecovery(data);
            }
        },
        [completeRecovery, isDirty, isSubmitting, isValid, loading],
    );

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <span className={styles.setNewPassTxt}>Set a new password for your account:</span>
            <Controller
                render={({ field: { onChange, value, name } }) => {
                    return (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            type={showPassword ? "text" : "password"}
                            onInnerIconClick={() => setShowPassword(!showPassword)}
                            label="forgot_password_new_pass"
                            innerIconClass={classNames("viewPass", showPassword && "showPass")}
                            error={errors.password}
                        />
                    );
                }}
                control={control}
                name="password"
            />

            <Controller
                render={({ field: { onChange, value, name } }) => {
                    return (
                        <Input
                            onChange={onChange}
                            value={value}
                            name={name}
                            type={showConfirmPassword ? "text" : "password"}
                            onInnerIconClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            label="forgot_password_confirm_new_pass"
                            innerIconClass={classNames(
                                "viewPass",
                                showConfirmPassword && "showPass",
                            )}
                            error={errors.password_confirm}
                        />
                    );
                }}
                control={control}
                name="password_confirm"
            />

            <Button
                type="submit"
                variant="secondary"
                disabled={!isDirty || !isValid || isSubmitting || loading}>
                <Translate tid="forgot_password_apply" />
            </Button>
        </form>
    );
};

export default Step2;
