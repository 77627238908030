import { ITopLeagueFeedResponse, ITournamentFeedResponse } from "@finbackoffice/websocket-client";
import { FC, memo } from "react";
import { useCollapseMargin } from "@finbackoffice/site-core";
import classNames from "classnames";
import { useSocketStorage, FeedTypeEnum } from "hooks";
import { Svg } from "components/base/svg/Svg";
import SportIcon from "components/base/sport-icon/SportIcon";
import LiveSidebarGamesContainer from "./games/LiveSidebarGamesContainer";
import styles from "./live-sidebar-tournament.module.sass";

type IProps = {
    tournament: ITopLeagueFeedResponse | ITournamentFeedResponse;
    sportId: number;
    eventIds?: number[];
    openedByDefault?: boolean;
};

const LiveSidebarTournament: FC<IProps> = ({
    tournament,
    sportId,
    eventIds,
    openedByDefault = true,
}) => {
    const { getEvents } = useSocketStorage(FeedTypeEnum.live);
    const { opened, animating, headerRef, containerRef } = useCollapseMargin(openedByDefault);

    return (
        <div className={styles.tournament}>
            <div
                className={classNames(styles.header, {
                    [styles.opened]: opened,
                    [styles.topTournamentHeader]: tournament.is_top,
                })}
                ref={!openedByDefault ? headerRef : null}>
                <SportIcon sportId={sportId} />
                <span className={styles.tournamentName}>
                    {(tournament as ITopLeagueFeedResponse).category_name &&
                        `${(tournament as ITopLeagueFeedResponse).category_name} / `}
                    {tournament.name}
                </span>
                {tournament.is_top && (
                    <Svg
                        src="/common/desktop/base-icons/top-leagues.svg"
                        wrapper="span"
                        className="svg-icon"
                    />
                )}
                <span>{tournament.games_count}</span>
            </div>
            {(opened || animating) && (
                <div ref={containerRef}>
                    <LiveSidebarGamesContainer
                        games={
                            eventIds
                                ? getEvents(tournament.id).filter((event) =>
                                      eventIds.includes(event.id),
                                  )
                                : getEvents(tournament.id)
                        }
                    />
                </div>
            )}
        </div>
    );
};

export default memo(LiveSidebarTournament);
