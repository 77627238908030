import classNames from "classnames";
import { useRouter } from "next/router";
import { FC, useCallback, useContext } from "react";
import { useRuntimeConfig, useSiteConfig, useSportsbookConfig } from "@finbackoffice/site-core";
import { SportsbookLayoutEnum } from "@finbackoffice/fe-core";
import ActiveLink from "components/base/active-link/ActiveLink";
import Translate from "components/base/translate/Translate";
import Img from "components/base/img/Img";
import { Svg } from "components/base/svg/Svg";
import { RouteNames } from "utils/constants";
import { SportsbookContext, MultiViewContext } from "contexts";
import styles from "./middle-area-header.module.sass";

const MiddleAreaHeader: FC = () => {
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");
    const sportsbookConfig = useSportsbookConfig();
    const multiviewEnabled = useSiteConfig<boolean>("multiview");
    const router = useRouter();
    const { sidebarCollapsed, setSidebarCollapsed } = useContext(SportsbookContext);
    const { removeAllGames, columnMode, setColumnMode } = useContext(MultiViewContext);
    const isMultiView = router.pathname.includes(RouteNames.MULTI_VIEW);
    const isFlatLayout = sportsbookConfig.type === SportsbookLayoutEnum.Flat;

    const handleCollapseArrowClick = useCallback(() => {
        if (!isMultiView) {
            setSidebarCollapsed(!sidebarCollapsed);
        }
    }, [isMultiView, setSidebarCollapsed, sidebarCollapsed]);

    return (
        <div className={styles.middleAreaHeader}>
            <span
                className={classNames(
                    styles.sidebarButton,
                    sidebarCollapsed && styles.collapsed,
                    isMultiView && styles.disabled,
                )}
                onClick={handleCollapseArrowClick}>
                <Img
                    source={`${ASSETS_URL}/common/desktop/base-icons/arrow-white.svg`}
                    alt="arrow"
                    width={10}
                    height={5}
                    wrapperClassName={styles.arrow}
                />
                <Img
                    source={`${ASSETS_URL}/common/desktop/base-icons/arrow-white.svg`}
                    alt="arrow"
                    width={10}
                    height={5}
                    wrapperClassName={styles.arrow}
                />
            </span>
            {multiviewEnabled && (
                <ActiveLink
                    href={`/${RouteNames.SPORT}/${RouteNames.MULTI_VIEW}`}
                    activeClassName={styles.active}>
                    <Translate tid="pageName_multiView" />
                </ActiveLink>
            )}

            {isMultiView && (
                <>
                    <div className={styles.multiViewColumnMode}>
                        {!isFlatLayout && (
                            <>
                                <Svg
                                    src="/common/desktop/base-icons/multiview-2.svg"
                                    wrapper="span"
                                    className={classNames(columnMode === "2" && styles.active)}
                                    onClick={() => setColumnMode("2")}
                                />
                                <Svg
                                    src="/common/desktop/base-icons/multiview-3.svg"
                                    wrapper="span"
                                    className={classNames(columnMode === "3" && styles.active)}
                                    onClick={() => setColumnMode("3")}
                                />
                            </>
                        )}
                    </div>
                    <Svg
                        src="/common/desktop/base-icons/multiview-remove.svg"
                        wrapper="span"
                        className={styles.multiVewRemoveBtn}
                        onClick={() => removeAllGames()}
                    />
                </>
            )}

            <ActiveLink
                href={`/${RouteNames.SPORT}/${RouteNames.CALENDAR}`}
                activeClassName={styles.active}>
                <>
                    {isFlatLayout && (
                        <Svg
                            src="/common/desktop/base-icons/calendar.svg"
                            wrapper="span"
                            className="svg-icon"
                        />
                    )}
                    <Translate tid="pageName_calendar" />
                </>
            </ActiveLink>
        </div>
    );
};

export default MiddleAreaHeader;
