import { FC, useCallback, useContext } from "react";
import classNames from "classnames";
import { OddsChangeMode, TranslationScopes } from "@finbackoffice/enums";
import { BetSlipContext } from "@finbackoffice/site-core";
import Translate from "components/base/translate/Translate";
import styles from "./accept-bet-placement.module.sass";

const AcceptBetPlacement: FC = () => {
    const { setBetMode, betMode } = useContext(BetSlipContext);

    const onBetModeChange = useCallback(
        (val: OddsChangeMode) => {
            setBetMode(val);
        },
        [setBetMode],
    );

    return (
        <div className={styles.acceptBetPlacement}>
            <p className={styles.placementText}>
                <Translate
                    tid="betSlip_acceptBetPlacementTitle"
                    namespace={TranslationScopes.BetSlip}
                />
            </p>
            <div className={styles.buttonsContainer}>
                <span
                    className={classNames(betMode === OddsChangeMode.Exact && styles.checked)}
                    onClick={() => onBetModeChange(OddsChangeMode.Exact)}>
                    <i />
                    <Translate
                        tid="betSlip_acceptBetPlacementNone"
                        namespace={TranslationScopes.BetSlip}
                    />
                </span>
                <span
                    className={classNames(betMode === OddsChangeMode.Higher && styles.checked)}
                    onClick={() => onBetModeChange(OddsChangeMode.Higher)}>
                    <i />
                    <Translate
                        tid="betSlip_acceptBetPlacementHigher"
                        namespace={TranslationScopes.BetSlip}
                    />
                </span>
                <span
                    className={classNames(betMode === OddsChangeMode.Any && styles.checked)}
                    onClick={() => onBetModeChange(OddsChangeMode.Any)}>
                    <i />
                    <Translate
                        tid="betSlip_acceptBetPlacementAny"
                        namespace={TranslationScopes.BetSlip}
                    />
                </span>
            </div>
        </div>
    );
};

export default AcceptBetPlacement;
