import { GetServerSidePropsContext, Page } from "next";
import React from "react";
import { PopupTags, SiteContentScopes } from "@finbackoffice/enums";
import { TranslationScopes } from "@finbackoffice/enums";
import { withSsrCasinoFavorites } from "@finbackoffice/site-server-core";
import ErrorBoundary from "components/base/error-boundary/ErrorBoundary";
import Seo from "components/base/seo/Seo";
import Home from "components/pages/home/Home";
import { getServerData } from "services/ssr/getServerData";
import { getSportsbookLayout } from "components/layout/sportsbook/SportsbookLayout";
import {
    clientBFFSingleton,
    createGetServerSideProps,
    CommonSsrProps,
    getServerRuntimeConfig,
} from "services/ssr";
import { getCurrentLocale } from "utils/helpers";

export const getServerSideProps = createGetServerSideProps<CommonSsrProps>(
    async (context: GetServerSidePropsContext) => {
        const runtimeConfig = await getServerRuntimeConfig();
        const client = clientBFFSingleton.getInstance(context.req.cookies.jwt);
        const locale = getCurrentLocale(runtimeConfig, context.locale);

        const [{ translations, configs, profile, popupBanners, banners }, casinoFavoriteGames] =
            await Promise.all([
                getServerData(runtimeConfig, context, client, {
                    popupBanners: { device: PopupTags.Desktop },
                    translationScope: [
                        TranslationScopes.Home,
                        TranslationScopes.Casino,
                        TranslationScopes.CasinoSlots,
                        TranslationScopes.CasinoLiveCasino,
                        TranslationScopes.CasinoWidgets,
                        TranslationScopes.PreMatch,
                    ],
                    banners: { scope: [SiteContentScopes.Homepage] },
                }),
                context.req.cookies.jwt ? withSsrCasinoFavorites(client) : undefined,
            ]);

        const props = {
            configs,
            runtimeConfig,
            jwt: context.req.cookies.jwt || "",
            profile: profile || null,
            locale,
            translations,
            banners: banners.items,
            popupBanners: popupBanners || [],
            casinoFavoriteGames: casinoFavoriteGames?.items || null,
        };

        return {
            props,
        };
    },
);

const IndexPage: Page = () => {
    return (
        <ErrorBoundary name={Home.name}>
            <Seo page={TranslationScopes.Home} />
            <Home />
        </ErrorBoundary>
    );
};

IndexPage.getLayout = getSportsbookLayout;

export default IndexPage;
