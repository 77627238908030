import { FC, useMemo } from "react";
import classNames from "classnames";
import { useRuntimeConfig } from "@finbackoffice/site-core";
import { NotificationAPI } from "rc-notification";
import Img from "components/base/img/Img";
import { INoticeEvent } from "contexts";
import Translate from "../translate/Translate";
import Button from "../button/Button";
import styles from "./site-notification.module.sass";

export type ISiteNotificationVariant = "default" | "modal";
export interface ISiteNotificationProps extends INoticeEvent {
    variant?: ISiteNotificationVariant;
    instance?: NotificationAPI;
}

const SiteNotification: FC<ISiteNotificationProps> = ({
    type,
    title,
    message,
    variant = "default",
    instance,
}) => {
    const ASSETS_URL = useRuntimeConfig("ASSETS_URL");

    const renderContent = useMemo(() => {
        if (variant === "modal") {
            return (
                <>
                    <div>{title}</div>
                    <Translate text={message} dangerous>
                        <p />
                    </Translate>
                    <Button
                        type="button"
                        variant="primary"
                        onClick={() => instance?.close(title)}
                        className={styles.closeButton}>
                        <Translate tid="base_close" />
                    </Button>
                </>
            );
        }
        return (
            <div className={classNames(styles.notification, styles[type])}>
                <span>
                    <Img
                        source={`${ASSETS_URL}/common/desktop/base-icons/notification-${type}.svg`}
                        alt={type}
                        width={14}
                        height={14}
                    />
                    {title}
                </span>
                <span>{message}</span>
            </div>
        );
    }, [ASSETS_URL, instance, message, title, type, variant]);

    return renderContent;
};

export default SiteNotification;
