import { FC, useContext, useEffect, useMemo, useState } from "react";
import { UserStatus } from "@finbackoffice/enums";
import {
    ConfigContext,
    ModalsContext,
    UserAccountContext,
    useTranslation,
} from "@finbackoffice/site-core";
import Button from "components/base/button/Button";
import Loading from "components/base/loading/Loading";
import Translate from "components/base/translate/Translate";
import VerificationItem from "./item/VerificationItem";
import styles from "./verification.module.sass";

const Verification: FC = () => {
    const { t } = useTranslation();
    const { userData, userProfileStatus } = useContext(UserAccountContext);
    const { siteRegistrationConfig } = useContext(ConfigContext);
    const { setCurrentModal } = useContext(ModalsContext);
    const [visibility, setVisibility] = useState({ phone: false, email: false });

    const onFinishClickHandler = async () => {
        if (userData?.status !== UserStatus.Draft) {
            setCurrentModal(null);
        }
    };

    const renderTitle = useMemo(() => {
        if (userProfileStatus === UserStatus.ReVerification) {
            return (
                <Translate
                    tid="verification_reverification_heading"
                    replace={{
                        val: `${visibility.email ? t("userData_email") : ""}${visibility.phone ? `${visibility.email ? "," : ""} ${t("userData_mobile")}` : ""}`,
                    }}
                />
            );
        }

        return <Translate tid="verification_heading" />;
    }, [t, userProfileStatus, visibility.email, visibility.phone]);

    useEffect(() => {
        setVisibility({
            phone:
                (siteRegistrationConfig?.require_phone_verification || false) &&
                !userData?.phone_verified,
            email:
                (siteRegistrationConfig?.require_email_verification || false) &&
                !userData?.email_verified,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <section className={styles.container}>
            <div className={styles.header}>{renderTitle}</div>
            <div className={styles.verification} data-testid="verification-container">
                {userData?.status === UserStatus.Draft && (
                    <span className={styles.pleaseVerifyTxt}>
                        <Translate tid="verification_text" />
                    </span>
                )}
                {userData && siteRegistrationConfig ? (
                    <>
                        {visibility.email && (
                            <VerificationItem type="email" verified={userData.email_verified}>
                                <Translate
                                    tid="verification_emailDescription"
                                    replace={{ email: userData.email || "" }}
                                    dangerous>
                                    <div />
                                </Translate>
                            </VerificationItem>
                        )}
                        {visibility.phone && (
                            <VerificationItem type="phone" verified={userData.phone_verified}>
                                <Translate
                                    tid="verification_phoneDescription"
                                    replace={{ phone: userData.phone || "" }}
                                    dangerous>
                                    <div />
                                </Translate>
                            </VerificationItem>
                        )}
                    </>
                ) : (
                    <Loading />
                )}

                <Button
                    type="button"
                    variant="primary"
                    onClick={onFinishClickHandler}
                    disabled={userData?.status === UserStatus.Draft}
                    className={styles.finishButton}>
                    <Translate tid="verification_finish" />
                </Button>
            </div>
        </section>
    );
};

export default Verification;
