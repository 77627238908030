import classNames from "classnames";
import { useCallback, FC, ReactElement, useContext } from "react";
import { HoursRange } from "@finbackoffice/websocket-client";
import { PrematchContext } from "contexts";
import Translate from "components/base/translate/Translate";

type TimeFilterData = {
    label: string | ReactElement;
    value: HoursRange;
};

const timeFilterData: TimeFilterData[] = [
    {
        label: "All",
        value: HoursRange.ALL,
    },
    {
        label: <Translate tid="sidebar_prematch_24HoursBets" />,
        value: HoursRange.TWENTY_FOUR,
    },
    {
        label: "12H",
        value: HoursRange.TWELVE,
    },
    {
        label: "6H",
        value: HoursRange.SIX,
    },
    {
        label: "1H",
        value: HoursRange.ONE,
    },
];

type Props = {
    onSelect?: () => void;
};

const PrematchTimeFilterTabs: FC<Props> = ({ onSelect }) => {
    const { setHoursRange, hoursRange } = useContext(PrematchContext);
    const handleSelectFilter = useCallback(
        (value: HoursRange) => {
            if (value !== hoursRange) {
                setHoursRange(value);
            }

            typeof onSelect === "function" && onSelect();
        },
        [hoursRange, onSelect, setHoursRange],
    );

    return (
        <div className="main-tabs">
            {timeFilterData.map(({ label, value }) => (
                <div
                    key={value}
                    data-testid={`tab-${value || label}`}
                    className={classNames("main-tabs-tab", value === hoursRange && "active")}
                    onClick={() => handleSelectFilter(value)}>
                    {label}
                </div>
            ))}
        </div>
    );
};

export default PrematchTimeFilterTabs;
