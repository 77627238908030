import { useRouter } from "next/router";
import { RouteNames } from "utils/constants";
import { FeedTypeEnum } from "./useSocketStorage";

export const useGetCurrentFeedType = (): FeedTypeEnum => {
    const { route, pathname } = useRouter();
    const [, , feed] = route.split("/");

    if (pathname.includes(RouteNames.CALENDAR) || pathname.includes(RouteNames.MULTI_VIEW)) {
        return FeedTypeEnum.live;
    } else if (pathname.includes(RouteNames.UPCOMING)) {
        return FeedTypeEnum.prematch;
    }

    return feed as unknown as FeedTypeEnum;
};
