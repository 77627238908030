import { FC, useCallback } from "react";
import { useRouter } from "next/router";
import { formatAppNameText, HeaderLayoutEnum, ISiteConfigLayouts } from "@finbackoffice/fe-core";
import { useRuntimeConfig, useSiteConfig } from "@finbackoffice/site-core";
import { isBrazilianSkin, SkinVersions } from "@finbackoffice/site-server-core";
import Translate from "components/base/translate/Translate";
import ActiveLink from "components/base/active-link/ActiveLink";
import { Svg } from "components/base/svg/Svg";
import { RouteNames } from "utils/constants";
import styles from "./header-nav.module.sass";

enum HeaderNavEnum {
    Home = "home",
    Prematch = "prematch",
    Live = "live",
    Livecasino = "livecasino",
    Slots = "slots",
    Bonuses = "bonuses",
}

const HeaderNav: FC = () => {
    const COMMON_SITE_CONFIGS = useRuntimeConfig("COMMON_SITE_CONFIGS");
    const router = useRouter();
    const layoutsConfig = useSiteConfig<ISiteConfigLayouts>("layouts");
    const isV2Header = layoutsConfig.header === HeaderLayoutEnum.V2;
    const isV1Header = layoutsConfig.header === HeaderLayoutEnum.V2;
    const headerLinksDefaultConfig: HeaderNavEnum[] = [
        HeaderNavEnum.Home,
        HeaderNavEnum.Prematch,
        HeaderNavEnum.Live,
        HeaderNavEnum.Livecasino,
        HeaderNavEnum.Slots,
        HeaderNavEnum.Bonuses,
    ];
    const headerLinksBrazilianConfig: HeaderNavEnum[] = [
        HeaderNavEnum.Home,
        HeaderNavEnum.Prematch,
        HeaderNavEnum.Live,
        HeaderNavEnum.Slots,
        HeaderNavEnum.Livecasino,
        HeaderNavEnum.Bonuses,
    ];

    const renderNavItem = useCallback(
        (name: HeaderNavEnum) => {
            switch (name) {
                case HeaderNavEnum.Home:
                    return (
                        <ActiveLink key={name} href="/" activeClassName="header-menu-item-active">
                            <>
                                {isV2Header &&
                                    ![SkinVersions.Betvivo, SkinVersions.Betvivocomz].includes(
                                        COMMON_SITE_CONFIGS.skinVersion as SkinVersions,
                                    ) && (
                                        <Svg
                                            src={`/${formatAppNameText(
                                                COMMON_SITE_CONFIGS.appName,
                                            )}/desktop/icons/menu-home.svg`}
                                            wrapper="span"
                                            className="svg-sport-icon"
                                        />
                                    )}
                                <Translate tid="header_home" />
                            </>
                        </ActiveLink>
                    );
                case HeaderNavEnum.Prematch:
                    return (
                        <ActiveLink
                            key={name}
                            href={`/${RouteNames.SPORT}/${RouteNames.UPCOMING}`}
                            activeClassName="header-menu-item-active"
                            includes={`/${RouteNames.SPORT}/${RouteNames.PREMATCH}`}
                            forceActive={router.pathname.includes(
                                `/${RouteNames.SPORT}/${RouteNames.UPCOMING}`,
                            )}
                            data-testid="menu-prematch">
                            <>
                                {isV2Header && (
                                    <Svg
                                        src="/common/desktop/base-icons/sport-icon.svg"
                                        wrapper="span"
                                        className="svg-sport-icon"
                                    />
                                )}
                                <Translate tid="pageName_sport" />
                            </>
                        </ActiveLink>
                    );
                case HeaderNavEnum.Live:
                    return (
                        <ActiveLink
                            key={name}
                            href={`/${RouteNames.SPORT}/${RouteNames.LIVE}`}
                            activeClassName="header-menu-item-active"
                            includes={`/${RouteNames.SPORT}/${RouteNames.LIVE}`}
                            forceActive={router.pathname.indexOf(RouteNames.CALENDAR) !== -1}
                            data-testid="menu-live">
                            <>
                                {isV2Header && (
                                    <Svg
                                        src="/common/desktop/base-icons/menu-live.svg"
                                        wrapper="span"
                                        className="svg-sport-icon"
                                    />
                                )}
                                <Translate tid="pageName_live" />
                            </>
                        </ActiveLink>
                    );
                case HeaderNavEnum.Livecasino:
                    return (
                        <ActiveLink
                            key={name}
                            href={`/${RouteNames.CASINO}/${RouteNames.LIVE_CASINO}`}
                            includes={`/${RouteNames.LIVE_CASINO}`}
                            activeClassName="header-menu-item-active"
                            data-testid="menu-live-casino">
                            <>
                                {isV2Header && (
                                    <Svg
                                        src={`/${formatAppNameText(
                                            COMMON_SITE_CONFIGS.appName,
                                        )}/common/base-icons/live-casino-icon.svg`}
                                        wrapper="span"
                                        className="svg-sport-icon"
                                    />
                                )}
                                <Translate tid="pageName_livecasino" />
                            </>
                        </ActiveLink>
                    );
                case HeaderNavEnum.Slots:
                    return (
                        <ActiveLink
                            key={name}
                            href={`/${RouteNames.CASINO}/${RouteNames.SLOTS}`}
                            includes={`/${RouteNames.SLOTS}`}
                            activeClassName="header-menu-item-active"
                            data-testid="menu-slots">
                            <>
                                {isV2Header && (
                                    <Svg
                                        src={`/${formatAppNameText(
                                            COMMON_SITE_CONFIGS.appName,
                                        )}/common/base-icons/slots-icon.svg`}
                                        wrapper="span"
                                        className="svg-sport-icon"
                                    />
                                )}
                                <Translate tid="pageName_slots" />
                            </>
                        </ActiveLink>
                    );
                case HeaderNavEnum.Bonuses:
                    return (
                        <ActiveLink
                            key={name}
                            href={`/${RouteNames.BONUSES}`}
                            includes={`/${RouteNames.BONUSES}`}
                            activeClassName="header-menu-item-active"
                            data-testid="menu-bonuses">
                            <>
                                {isV2Header && (
                                    <Svg
                                        src="/common/desktop/base-icons/menu-bonuses.svg"
                                        wrapper="span"
                                        className="svg-sport-icon"
                                    />
                                )}
                                <Translate tid="footer_bonuses" />
                                {isV1Header && (
                                    <Svg
                                        src="/common/desktop/base-icons/bonuses.svg"
                                        wrapper="span"
                                        className={styles.bonusesIcon}
                                    />
                                )}
                            </>
                        </ActiveLink>
                    );
                default:
                    return null;
            }
        },
        [
            COMMON_SITE_CONFIGS.appName,
            COMMON_SITE_CONFIGS.skinVersion,
            isV1Header,
            isV2Header,
            router.pathname,
        ],
    );

    return (
        <nav className={styles.nav}>
            {(isBrazilianSkin(COMMON_SITE_CONFIGS.skinVersion as SkinVersions)
                ? headerLinksBrazilianConfig
                : headerLinksDefaultConfig
            ).map(renderNavItem)}
        </nav>
    );
};
export default HeaderNav;
