import classNames from "classnames";
import { FC, PropsWithChildren } from "react";
import { roboto } from "fonts/roboto";
import styles from "./sir-widget.module.sass";

export type ISIRWidget = {
    matchId: string;
    sportId: number;
    regionId: number;
    onLoad?: (...args: unknown[]) => any;
    onError?: () => void;
    containerSelector: string;
    children?: (isLicenced: boolean, loading: boolean) => any;
};

type ISIRWidgetCore = {
    isLicenced: boolean;
    loading: boolean;
};

export const SIRWidgetCore: FC<PropsWithChildren<ISIRWidgetCore>> = ({
    isLicenced,
    loading,
    children,
}) => {
    return (
        <div
            className={classNames(
                "widgets",
                roboto.variable,
                styles.customWidget,
                isLicenced && !loading && styles.loadingWidget,
            )}>
            {children}
            <div className={`${styles.widgetContainer} ${loading ? styles.isLoading : ""}`}>
                <div className="sr-widget sr-widget-lmtplus" />
                <div className="sr-widget sr-widget-compact" />
            </div>
        </div>
    );
};
