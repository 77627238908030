import { createContext, FC, memo, useCallback, useContext, useMemo } from "react";
import { IMarketFeedResponse, IMarketOutcomeFeedResponse } from "@finbackoffice/clientbff-client";
import { IEventFeedResponse } from "@finbackoffice/websocket-client";
import { BetSlipContext, IMainOddsState, useMainMarketOdds } from "@finbackoffice/site-core";
import { MarketOutcomeStatus, MarketStatus } from "@finbackoffice/enums";
import dynamic from "next/dynamic";
import { MarketUpdatesContext } from "contexts";

const Default = dynamic(() => import("./layouts/default/Default"), {
    ssr: false,
});

const Top = dynamic(() => import("./layouts/top/Top"), {
    ssr: false,
});

type Props = {
    game: IEventFeedResponse;
    layout?: "default" | "top";
    index: number;
};

type IHomeTournamentGameItemContext = {
    game: IEventFeedResponse;
    mainMarket?: IMarketFeedResponse;
    mainMarketId: number | null;
    mainOdds?: IMainOddsState;
    mainMarketStatus?: MarketStatus;
    onOutcomeClick: (marketId: number, outcome: IMarketOutcomeFeedResponse) => void;
    columnCount: string;
    isInternationalCategory: boolean;
    index: number;
};

export const HomeTournamentGameItemContext = createContext<IHomeTournamentGameItemContext>(
    null as any,
);

const HomeTournamentGameItem: FC<Props> = ({ game, index, layout = "default" }) => {
    const { addBetItem, removeBetItem, isOutcomeSelected } = useContext(BetSlipContext);
    const { subscribeMarket, unsubscribeMarket } = useContext(MarketUpdatesContext);
    const gameId = game?.id;
    const homeName = game?.home_team?.name || "";
    const awayName = game?.away_team?.name || "";
    const sportId = game?.sport_id;
    const {
        mainMarket,
        mainMarketName,
        mainMarketStatus,
        mainMarketTemplateId,
        mainMarketId,
        mainOdds,
        columnCount,
    } = useMainMarketOdds(sportId, game.markets);
    const isInternationalCategory = game.category_id === 4;

    const onOutcomeClick = useCallback(
        (marketId: number, outcome: IMarketOutcomeFeedResponse) => {
            if (mainMarketStatus) {
                if (!isOutcomeSelected(outcome.id)) {
                    addBetItem({
                        gameId,
                        marketGroupId: mainMarketTemplateId?.toString() || "",
                        marketId,
                        outcomeId: outcome.id,
                        team1Name: homeName,
                        team2Name: awayName,
                        marketName: mainMarketName,
                        marketStatus: mainMarketStatus,
                        outcomeName: outcome.name || "",
                        outcomeValue: outcome.odds,
                        outcomeStatus: MarketOutcomeStatus.Active,
                        outcomeInitValue: outcome.odds,
                        stake: "",
                        loading: false,
                        accepted: false,
                    });
                    subscribeMarket(gameId, marketId, outcome.id);
                } else {
                    removeBetItem(outcome.id);
                    unsubscribeMarket(outcome.id);
                }
            }
        },
        [
            addBetItem,
            awayName,
            gameId,
            homeName,
            mainMarketName,
            mainMarketStatus,
            mainMarketTemplateId,
            subscribeMarket,
            unsubscribeMarket,
            removeBetItem,
            isOutcomeSelected,
        ],
    );

    const renderLayout = useMemo(() => {
        switch (layout) {
            case "top":
                return <Top />;
            default:
                return <Default />;
        }
    }, [layout]);

    const value = useMemo(
        () => ({
            game,
            mainMarket,
            mainMarketId,
            mainOdds,
            mainMarketStatus,
            onOutcomeClick,
            columnCount,
            isInternationalCategory,
            index,
        }),
        [
            game,
            mainMarket,
            mainMarketId,
            mainOdds,
            mainMarketStatus,
            onOutcomeClick,
            columnCount,
            isInternationalCategory,
            index,
        ],
    );

    return (
        <HomeTournamentGameItemContext.Provider value={value}>
            {renderLayout}
        </HomeTournamentGameItemContext.Provider>
    );
};

export default memo(HomeTournamentGameItem);
