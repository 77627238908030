import classNames from "classnames";
import {
    useState,
    useEffect,
    forwardRef,
    useImperativeHandle,
    useRef,
    cloneElement,
    ReactElement,
} from "react";
import styles from "./fade-in-animation.module.sass";

interface IFadeInAnimationProps {
    children: ReactElement;
    variant?: string;
}

type FadeInAnimationHandle = {
    clearVisible: () => void;
    forceOpen: () => void;
};

const FadeInAnimation = forwardRef<FadeInAnimationHandle, IFadeInAnimationProps>(
    ({ children, variant = "opacity" }, ref) => {
        const [visible, setVisible] = useState<boolean>(false);
        const animationId = useRef<number>();

        useEffect(() => {
            animationId.current = requestAnimationFrame(() => {
                setVisible(true);
            });

            return () => {
                if (animationId.current) {
                    cancelAnimationFrame(animationId.current);
                }
            };
        }, []);

        useImperativeHandle(ref, () => ({
            clearVisible: () => setVisible(false),
            forceOpen: () => setVisible(true),
        }));

        return cloneElement(children, {
            className: classNames(
                children?.props?.className,
                styles["not-visible"],
                visible && styles.visible,
                styles[variant],
            ),
        });
    },
);

export default FadeInAnimation;
